import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../context/auth-context";
import axios from "axios";
import "./Lecture.css";
import useMobileDetect from "../hooks/use-mobile";
const Lecture = () => {
  const lectureId = useParams().lectureId;
  const auth = useContext(AuthContext);
  const [lecture, setLecture] = useState();
  const { isMobile } = useMobileDetect();

  useEffect(() => {
    const getLecture = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}lecture/getUserLectureById/${lectureId}`,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        )
        .then((response) => {
          setLecture(response.data.lecture);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    getLecture();
  }, [auth]);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = lecture.fileUrl;
    link.setAttribute("download", lecture.title);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function toggleFullscreen() {
    if (!document.fullscreenElement) {
      document.getElementById("vid-cont").requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }

  return (
    <div style={{ justifyContent: "center" }}>
      {lecture && (
        <div className="video-player">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: "40px",
            }}
          >
            {lecture.isVideo && (
              <div
                id="vid-cont"
                className="video-container"
                style={{
                  width: isMobile ? "90%" : "60%",
                }}
              >
                <video
                  src={lecture.videoUrl}
                  width="100%"
                  height="auto"
                  controls
                  autoPlay
                  controlsList="nodownload"
                  style={{ borderRadius: "10px" }}
                  onMouseUp={toggleFullscreen}
                />

                {/* Watermark */}
                <div className="watermark">{auth.email}</div>
                <button
                  className="fullscreen-button"
                  onClick={toggleFullscreen}
                  onDoubleClick={() => {}}
                >
                  شاشه كامله
                </button>
              </div>
            )}
            {!lecture.isVideo && lecture.fileUrl && (
              <div
                role="button"
                onClick={(e) => {
                  e.preventDefault();
                  handleDownload();
                }}
                style={{
                  display: "inline-block",
                  padding: "10px 20px",
                  color: "#fff",
                  backgroundColor: "#007bff",
                  textDecoration: "none",
                  borderRadius: "5px",
                  fontSize: "16px",
                  transition: "background-color 0.3s",
                  cursor: "pointer",
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = "#0056b3")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor = "#007bff")
                }
              >
                PDF تحميل المحاضرة بصيغة
              </div>
            )}
          </div>
          <div className="card-container">
            <div className="card">
              <h1>
                {lecture.title}
                <span> : عنوان الدرس</span>
              </h1>
              <p>
                {lecture.description}
                <span> : الوصف</span>
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Lecture;
